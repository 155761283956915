import React, { useEffect, useState } from 'react'
import { jsonExec } from '../../utils/jExec';

import css from './Quiz.module.scss'

export default function Quiz(props) {
    const [started, setStarted] = useState(false);

    const [screen, setScreen] = useState(0);

    const [correct, setCorrect] = useState(false);


    const queryParameters = new URLSearchParams(props.location.search)
    const sid = queryParameters.get('sid');

    useEffect(() => {
        setTimeout(() => {
            setStarted(true);
        }, 1000)
    }, [])

    const onAnswer = (correct) => {
        setCorrect(correct);
        setTimeout(() => setScreen(1), 500);
    }

    return (
        <div className={css.main}>
            <div onClick={() => setStarted(!started)} className={css.logo + (started ? ' ' + css.started : '')}><img src="/i/gof-logo.svg" /></div>
            <Question active={screen == 0} onAnswer={onAnswer} />
            <Answer correct={correct} active={screen == 1} onReset={() => setScreen(2)} />
            <Result correct={0} active={screen == 2} onReset={() => setScreen(3)} />
            <Result correct={10} active={screen == 3} onReset={() => setScreen(4)} />
            <Result correct={15} active={screen == 4} onReset={() => setScreen(5)} />
            <Result correct={20} active={screen == 5} onReset={() => setScreen(0)} />
        </div>
    )
}

const Result = ({ correct, active, onReset }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (active) {
            setShow(true);
        }
    }, [active])

    if (!active) return null;

    return <div className={css.answer + (show ? ' ' + css.done : '')}>
        {correct == 0 && <>
            <div>Вы не ответили правильно ни на один вопрос :(</div>
            <div>Пройдите квиз заново</div>
            <button onClick={onReset} >Пройду заново</button>
        </>}
        {correct > 0 && correct < 11 && <>
            <div>Вы правильно ответили на {correct} вопросов из 20</div>
            <div>Для получения приза нужно ответить правильно хотя бы на 11 вопросов из 20. Пройдите квиз заново.</div>
            <button onClick={onReset} >Пройду заново</button>
        </>}
        {correct >= 11 && correct < 20 && <>
            <div>Вы правильно ответили на {correct} вопросов из 20</div>
            <div>Поздравляю! Ты выиграл стикерпак Игр Будущего. Можешь получить его на стойке МинСпорта.</div>
            <div>Ты можешь получить браслет вместо стикерпака, если ответишь правильно на все вопросы..</div>
            <button onClick={onReset} >Возьму стикерпак</button>
            <button onClick={onReset} >Пройду заново</button>

        </>}
        {correct == 20 && <>
            <div>Вы правильно ответили на все вопросы!</div>
            <div>Поздравляю! Ты выиграл приз от Игр Будущего. Это или стикерпак или браслет от Игр Будущего. Можешь получить его на стойке МинСпорта.</div>
            <button onClick={onReset} >Хочу стикерпак</button>
            <button onClick={onReset} >Хочу браслет</button>
        </>}
    </div>


}

const Question = ({ active, onAnswer }) => {
    const [show, setShow] = useState(false);
    const [buttons, setButtons] = useState([])
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (active) {
            setButtons([0, 0, 0]);
            setDone(false);
            setShow(true);
        }
    }, [active])

    const onRight = () => {
        let x = [...buttons];
        x[0] = 1;
        setButtons(x);
        setDone(true);
        setTimeout(() => {
            setShow(false);
            onAnswer(true);
        }, 1000);
    }

    const onWrong = (idx) => {
        let x = [...buttons];
        x[0] = 1;
        x[idx] = -1;
        setButtons(x);
        setDone(true);
        setTimeout(() => {
            setShow(false);
            onAnswer(false);
        }, 1000);
    }



    const buttonClass = (idx) => {
        console.log(idx, buttons[idx]);
        if (buttons[idx] == -1) return css.wrong;
        if (buttons[idx] == 1) return css.right;
        return '';
    }

    if (!active) return null;

    return <div className={css.question + (show ? ' ' + css.started : '')}>
        <div>Вопрос 1 из 20</div>
        <div>Что означает термин Фиджитал?</div>
        <button disabled={done} className={buttonClass(0)} onClick={onRight}>Соединение физической и цифровой реальностей</button>
        <button disabled={done} className={buttonClass(1)} onClick={() => onWrong(1)}>Специальное устройство виртуальной реальности</button>
        <button disabled={done} className={buttonClass(2)} onClick={() => onWrong(2)}>Тип события в объектно-ориентированном программировании</button>
    </div>;
}

const Answer = ({ active, correct, onReset }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (active) {
            setShow(true);
        }
    }, [active])

    const onNext = () => {
        setShow(false);
        setTimeout(onReset, 500);
    }

    if (!active) return null;

    return <div className={css.answer + (show ? ' ' + css.done : '')}>
        {correct ? <div>Это правильный ответ!</div> : <div>Это неправильный ответ!</div>}
        <div>Концепция фиджитал подразумевает под собой соединение физической и цифровой реальностей.</div>
        <button onClick={onNext} >Дальше</button>
    </div>

}