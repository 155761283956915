import React, { useEffect, useState } from 'react'
import { useGeolocated } from "react-geolocated";
import { jsonExec } from '../../utils/jExec';

export default function CheckIn(props) {
    const [event, setEvent] = useState(false);
    const [saving, setSaving] = useState(false);
    const [done, setDone] = useState(false) ;

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    useEffect(() => {
        (async () => {
            if (!isGeolocationAvailable || !coords?.latitude || !coords?.longitude) return;
            const loaded = await jsonExec('online/event', { sid: sid, latitude: coords.latitude, longitude: coords.longitude });
            setEvent(loaded.event || false);
        })();
    }, [coords])

    const onCheckIn = async () => {
        setSaving(true) ;
        const loaded = await jsonExec('online/checkin', { sid: sid, event_id: event.stock_id });
        if (loaded.result) {
            setDone(true) ;
        }
        console.log (loaded) ;
        setSaving(false) ;
    }

    const queryParameters = new URLSearchParams(props.location.search)
    const sid = queryParameters.get('sid');

    return (
        <>
            <div><img src="/i/gof-logo.svg" style={{ width: 320, maxHeight: event ? "20vh" : "50vh" }} /></div>
            {!isGeolocationAvailable ? (
                <div style={{ textAlign: 'center' }}>Твой браузер не поддерживает геолокацию. К сожалению чек-ин на мероприятии сделать не получится :(</div>
            ) : !isGeolocationEnabled ? (
                <div style={{ textAlign: 'center' }}>Тебе надо разрешить мне доступ к твоей геолокации, чтобы сделать чек-ин на мероприятии и получить фиджитал-коины.</div>
            ) : event ? (
                <>
                    <div style={{ marginTop: 60, fontSize: 36, fontWeight: 'bold', textAlign: 'center' }}>{event.title}</div>
                    <div style={{ marginTop: 10, textAlign: 'center' }}>{event.description}</div>
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        {done ? <div>Чек-ин сделан. Фиджитал-коины начислены на твой счёт.</div> :
                        (saving ?
                            <div>Делаем чек-ин ...</div>
                            : <button onClick={onCheckIn} className="btn" style={{ fontSize: 30, maxWidth: 'calc(100vw - 40px)', width: 500, height: 80 }}>Чек-ин</button>
                        )}

                    </div>
                </>
            ) : (
                <div style={{ textAlign: 'center' }}>Выясняю, где ты сейчас ...</div>
            )}
        </>
    )
}
