import { useState } from "react";
import "./App.css";
import { Router } from "@gatsbyjs/reach-router";
import CheckIn from "./app/CheckIn/CheckIn";
import Quiz from "./app/Quiz/Quiz";

function App() {
	return <Router className="app">
		<CheckIn path="/checkin/"/>
		<Quiz path="/quiz/russia"/>
	</Router>
	// const [logged, setLogged] = useState(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ;
	// return <div className="app" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/i/bg.webp'})`}}>
	// 	{!logged ? <Auth onLogged={setLogged}/> : <Main logged={logged} onLogout={()=>setLogged(false)}/>}
	// </div>;
}

export default App;
